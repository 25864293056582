<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <vuexy-logo />
                <h2 class="brand-text text-primary m-auto pl-1">
                    Monika
                </h2>
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img fluid :src="imgUrl" alt="Login V2" />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <b-card-title title-tag="h2" class="font-weight-bold mb-1">
                        Forgot Password
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Please input your NIP
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginValidation">
                        <b-form class="auth-login-form mt-2" @submit.prevent>
                            <!-- email -->
                            <b-form-group label="NIP" label-for="login-nip">
                                <validation-provider #default="{ errors }" name="NIP" rules="required">
                                    <b-form-input id="login-nip" v-model="form.nip"
                                        :state="errors.length > 0 ? false:null" name="login-nip" placeholder="nip" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                    <small class="text-danger">{{ errMessage }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button class="mt-5" type="submit" variant="primary" block @click="forgotPassword()">
                                Send
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <b-card-text class="text-center mt-2">
                        <span>Already have Account? </span>
                        <b-link :to="{name:'login'}">
                            <span>&nbsp;Sign In</span>
                        </b-link>
                    </b-card-text>

                    <!-- divider -->
                    <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

                    <!-- social buttons -->
                    <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
    /* eslint-disable global-require */
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import VuexyLogo from '@core/layouts/components/Logo.vue'
    import {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
    } from 'bootstrap-vue'
    import {
        required,
        email
    } from '@validations'
    import {
        togglePasswordVisibility
    } from '@core/mixins/ui/forms'
    import store from '@/store/index'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import axios from 'axios'

    export default {
        components: {
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroup,
            BFormCheckbox,
            BCardText,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            VuexyLogo,
            ValidationProvider,
            ValidationObserver,
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                form: {
                    nip: ''
                },
                errMessage: '',
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                storage: [],
            }
        },
        computed: {
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            },
        },
        methods: {
            forgotPassword() {
                this.$http
                .post('auth/forgot-password', {
                    "nip": this.form.nip
                })
                .then((response) => {
                    location.href = "/login"
                    console.log(response.data.data)
                }).catch((errors) => {
                    this.errMessage = errors.response.data.message
                    console.log(errors.response)
                });
            },
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/pages/page-auth.scss';
</style>